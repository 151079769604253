import React, { lazy } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PrivateRoute from './components/login/PrivateRoute';

const Login = lazy(() => import("./pages/Login"));
const SignUp = lazy(() => import("./pages/SignUp"));
const Layout = lazy(() => import('./layout/Layout'));

const App = () => {
  return (
    <>
      <Router>
        <Switch>
          <Route path={"/login"} component={Login} />
          <Route path={"/register"} component={SignUp} />
          <PrivateRoute>
            {" "}
            <Route path="/" component={Layout} />
          </PrivateRoute>
          <Redirect exact from="/" to={"/login"} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
