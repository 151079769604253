import Cookies from "js-cookie";
import _ from "lodash";
import israelFlag from "./assets/img/images/israelFlag.jpg";
import USAFLAG from "./assets/img/images/USAFlag.png";
import JordanFlag from "./assets/img/images/jordanFlag.png";
import Resizer from "react-image-file-resizer";

export const UPLOAD_IMG_HEIGHT = 300;
export const MAX_UPLOAD_IMG_SIZE = 5000000;
export const MAX_IMAGE_FILES = 5;

export const COOKIE_KEYS = {
  ADMIN_INFO: "adminInfo",
  STORE_INFO: "storeInfo",
  SELECTED_STORE_IDX: "selectedStoreIndex",
  I18N: "i18next",
};

// Role
export const CEO_ROLE = "ADMIN";
export const STOREMGR_ROLE = "Store Manager";

// Store types
export const STORE_TYPE = {
  ONLINE_STORE: "Online Store",
  RESTAURANT: "Restaurant",
  UNKNOWN: "Unknown",
};

// Routes
export const ROUTE_PATHS = {
  LOGIN: "/login",
  SIGNUP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password/:token",
  DASHBOARD: "/dashboard",
  ADDON: "/attributes",
  EDIT_ADDON_GROUP: "/addongroup/:id",
  NEW_ADDON_GROUP: "/addongroup/new",
  NEW_ADDON: "/addon/new",
  EDIT_ADDON: "/addon/:id",
  COLLECTION: "/collection/new",
  EDIT_COLLECTION: "/collection/:id",
  VIEW_COLLECTION: "/collection/:id",
  COLLECTIONS: "/collections",
  COUPON: "/coupon/new",
  EDIT_COUPON: "/coupon/:id",
  VIEW_COUPON: "/coupon/:id",
  COUPONS: "/coupon",
  NEW_TOSAF: "/tosaf/new",
  EDIT_TOSAF: "/tosaf/:id",
  TOSAFOT: "/tosafot",
  VIEW_TOSAF: "/tosaf/:id",
  PRODUCTS: "/products",
  NEW_PRODUCT: "/product/add",
  EDIT_PRODUCT: "/product/:id",
  VIEW_PRODUCT: "/viewproduct/:id",
  PRODUCT_DETAILES: "/product/:id",
  REST_PRODUCTS: "/rest-products",
  NEW_REST_PRODUCT: "/rest-product/new",
  EDIT_REST_PRODUCT: "/rest-products/:id",
  REST_PRODUCT_DETAILES: "/rest-product/:id",
  CATEGORY: "/categories",
  NEW_CATEGORY: "/category/add",
  EDIT_CATEGORY: "/category/:id",
  SUB_CATEGORIES: "/subcategories",
  NEW_SUB_CATEGORY: "/sub-category/new",
  EDIT_SUB_CATEGORY: "/sub-category/:id",
  NEW_BRAND: "/brand/new",
  EDIT_BRAND: "/brand/:id",
  BRANDS: "/brands",
  EDIT_SUB_SUB_CATEGORY: "/nested-category/:id",
  USERS: "/users",
  USER_ORDER: "/user-order/:id",
  STAFF: "/our-staff",
  NEW_STAFF: "/our-staff/new",
  EDIT_STAFF: "/our-staff/:id",
  STORE_MANAGER: "/store-manager",
  NEW_STORE_MANAGER: "/store-manager/new",
  EDIT_STORE_MANAGER: "/store-manager/:id",
  EDIT_HEADER: "/edit-header",
  EDIT_STORE: "/edit-store",
  EDIT_FOOTER: "/edit-footer",
  ORDERS: "/orders",
  ORDER_DETAIL: "/order/:id",
  COUPONS: "/coupons",
  NEW_COUPON: "/coupon/new",
  EDIT_COUPON: "/coupon/:id",
  EDIT_PROFILE: "/edit-profile",
  INVOICE_SETTING: "/invoice-setting",
  CMS: "/cms",
  REGISTER_CMS: "/register-cms",
  UPDATE_CMS: "/update-cms/:id",
  STORES_MANAGEMENT: "/stores_management",
  PAYMENT_STATUS: "/payment-status",
  PLAN_PRICING: "/plan-pricing",
  PAYMENT_BILLING: "/payment-billing",
  LANGUAGES: "/languages",
  PASSWORD: "/update-password",
  NOTIFICATIONS: "/notifications",
  SELECTED_STORE: "/selectedstore",
  SPECIFICATIONS: "/specs",
  NEW_SPECIFICATION: "/specs/new",
  EDIT_SPECIFICATION: "/specs/edit",
  TICKET_ADD_SERVICE: "/ticket/add",
  TICKETS: "/tickets",
  TICKET_ACTION: "/ticket/action/:id",
  USER: "/client/add",
  CATALOG: "/catalog",
  CATALOG_ADD: "/catalogcategory/add",
  CATALOG_PROUDCT_ADD: "/catalogproduct/add",
  CATALOG_CATEGORY_PRODUCTS: "/catalogcategory/:id",
  CATALOG_PRODUCT: "/catalogproduct/:id",
  EDIT_CATALOG_PRODUCT: "/catalogproduct/edit/:id",
  /* Settings */
  SETTING: "/setting",
  SETTING_GENERAL: "/store-detail",
  UPDATE_STORE_INFO: "/store-detail/:id",

  PAGE_404: "/404",
  COMING_SOON: "/coming-soon",
};

// export const getHeaderTitle = (path) => {
//   if (path === ROUTE_PATHS.PRODUCTS) {
//     return {
//       title: "Product List",
//       desc: "Detailed information about your products",
//     };
//   } else if (
//     path === ROUTE_PATHS.CATEGORY ||
//     path === ROUTE_PATHS.NEW_CATEGORY ||
//     path === ROUTE_PATHS.EDIT_CATEGORY
//   ) {
//     return {
//       title: "Category List",
//       desc: "Detailed information about your category list",
//     };
//   } else if (
//     path === ROUTE_PATHS.SUB_CATEGORY ||
//     path === ROUTE_PATHS.NEW_SUB_CATEGORY ||
//     path === ROUTE_PATHS.EDIT_SUB_CATEGORY
//   ) {
//     return {
//       title: "SubCategory List",
//       desc: "Detailed information about your sub category list",
//     };
//   } else if (path === ROUTE_PATHS.ORDERS) {
//     return { title: "Orders", desc: "Detailed information about your orders" };
//   } else if (path === ROUTE_PATHS.COUPONS) {
//     return {
//       title: "Coupons",
//       desc: "Detailed information about your coupons",
//     };
//   } else if (
//     path === ROUTE_PATHS.ADDON ||
//     path === ROUTE_PATHS.NEW_ADDON ||
//     path === ROUTE_PATHS.EDIT_ADDON
//   ) {
//     return {
//       title: "Addons List",
//       desc: "Detailed information about your addons",
//     };
//   } else if (path === ROUTE_PATHS.USERS) {
//     return {
//       title: "Customer List",
//       desc: "Detailed information about your customer",
//     };
//   }
//   return { title: "", desc: "" };
// };

// language
export const languages = [
  {
    code: "en",
    name: "English",
    country_code: "en",
    img_url: "https://cdn-icons-png.flaticon.com/512/9994/9994148.png",
  },
  {
    code: "he",
    name: "עברית",
    country_code: "il",
    dir: "rtl",
    img_url:
      "https://media.istockphoto.com/id/1246322137/vector/flag-of-israel-round-icon-badge-or-button-israeli-national-symbol-template-design-vector.jpg?s=612x612&w=0&k=20&c=ar_iZutGtwTBRUzR_-SQFVdHGrbfRsc5Tg7PyTYHDsc=",
  },
];

// // Color
// export const COLORS = [
//   { name: "Black", en_text: "Black", value: "black" },
//   { name: "White", en_text: "White", value: "white" },
//   { name: "Gray", en_text: "Gray", value: "gray" },
//   { name: "Red", en_text: "Red", value: "red" },
//   { name: "Orange", en_text: "Orange", value: "orange" },
//   { name: "Yellow", en_text: "Yellow", value: "yellow" },
//   { name: "Green", en_text: "Green", value: "green" },
//   { name: "Teal", en_text: "Teal", value: "Teal" },
//   { name: "Blue", en_text: "Blue", value: "blue" },
//   { name: "Indigo", en_text: "Indigo", value: "indigo" },
//   { name: "Purple", en_text: "Purple", value: "purple" },
//   { name: "Pink", en_text: "Pink", value: "Pink" },
// ];

// export const variationColorSizeModel = {
//   image: "",
//   color: "",
//   size: [{ name: "", stock: 0 }],
// };

// export const variationColorModel = {
//   image: "",
//   color: "",
//   stock: 0,
// };

// export const VARIATION_TYPE = {
//   COLORS: 0,
//   COLOR_AND_SIZE: 1,
// };

// // Mqtt
// export const MQTT_STATE = {
//   DISCONNECTED: 0,
//   CONNECTED: 1,
//   CONNECTING: 2,
//   RECONNECTING: 3,
//   SUBSCRIBED: 4,
// };

// // Order
// export const NOTIFY_TYPE = {
//   ORDER_NEW: "New Order",
//   ORDER_PENDING: "Pending Order",
//   ORDER_PROCESSING: "Processing Order",
//   ORDER_CANCEL: "Cancel Order",
//   ORDER_DELIVERED: "Delivered Order",
//   STOCK_OUT_PRODUCT: "Stock Out",
// };

// export const ORDER_STATUS = {
//   NEW: "New",
//   PENDING: "Pending",
//   PROCESSING: "Processing",
//   DELIVERED: "Delivered",
//   CANCEL: "Cancel",
// };

// export const NotifyList = {
//   orders: {
//     groupName: "Orders",
//     content: {
//       confim: {
//         title: "Order Confirmation",
//         content:
//           "Sent automatically to the customer after they place their order",
//         enabled: true,
//       },
//       edited: {
//         title: "Order Edited",
//         content: "Sent to the customer after their order is edited",
//         enabled: true,
//       },
//       invoice: {
//         title: "Order Invoice",
//         content:
//           "Sent to the customer when the order has an outstanding balance",
//         enabled: true,
//       },
//       cancelled: {
//         title: "Order Cancelled",
//         content:
//           "Sent automatically to the customer if their order is cancelled",
//         enabled: true,
//       },
//       refund: {
//         title: "Order Refund",
//         content:
//           "Sent automatically to the customer if their order is refunded",
//         enabled: true,
//       },
//       paymentError: {
//         title: "Payment Error",
//         content: "Sent automatically to the customer",
//         enabled: true,
//       },
//     },
//   },
//   shipping: {
//     groupName: "Shipping",
//     content: {
//       fullfilmentRequest: {
//         title: "Fullfilment Request",
//         content: "Sent automatically to a third-party fulfillment",
//         enabled: true,
//       },
//       confirm: {
//         title: "Shipping Confirmation",
//         content:
//           "Sent automatically to the customer when their order is fulfilled",
//         enabled: true,
//       },
//       update: {
//         title: "Shipping Update",
//         content:
//           "Sent automatically to the customer if their fulfilled order tracking",
//         enabled: true,
//       },
//     },
//   },
//   localDelivery: {
//     groupName: "Local Delivery",
//     content: {
//       outOfDeliver: {
//         title: "Local order out for deliver",
//         content:
//           "Sent to the customer when their local order is out for delivery",
//         enabled: true,
//       },
//       delivered: {
//         title: "Local order delivered",
//         content: "Sent to the customer when their local order is delivered",
//         enabled: true,
//       },
//       missedDelivery: {
//         title: "Local order missed delivery",
//         content: "Sent to the customer when they miss a local delivery",
//         enabled: true,
//       },
//     },
//   },
//   customer: {
//     groupName: "Customer",
//     content: {
//       invite: {
//         title: "Customer account invite",
//         content: "Sent to the customer with account activation instructions",
//         enabled: true,
//       },
//       welcome: {
//         title: "Customer account welcome",
//         content:
//           "Sent automatically to the customer when they complete their account activation",
//         enabled: true,
//       },
//       resetPassword: {
//         title: "Customer account welcome",
//         content:
//           "Sent automatically to the customer when they ask to reset their accounts password",
//         enabled: true,
//       },
//       contact: {
//         title: "Contact customer",
//         content:
//           "Sent to the customer when you contact them from the orders or customers page",
//         enabled: true,
//       },
//     },
//   },
//   emailMarketing: {
//     groupName: "Email Marketing",
//     content: {
//       confim: {
//         title: "Customer marketing confirmation",
//         content:
//           "Sent to the customer automatically when they sign up for email marketing",
//         enabled: true,
//       },
//     },
//   },
// };

// // Common functions

// export const initYears = () => {
//   let curDate = new Date();
//   let currentYear = curDate.getFullYear();
//   let length = 10;
//   let years = Array.from({ length: length }).map((_, index) => {
//     return String(currentYear - (length - index - 1));
//   });
//   return years;
// };

// export const getStoreId = () => {
//   let storeId = Cookies.get(COOKIE_KEYS.STORE_INFO);
//   return storeId;
// };

// export const mqttPublish = (data) => {
//   if (window.client) {
//     const topic = `topic-`;
//     const qos = 2;
//     window.client.publish(topic, JSON.stringify(data), { qos }, (error) => {
//       if (error) {
//         console.log("MQTT Publish Error=>", error);
//       }
//     });
//   }
// };

// export const getAllStores = (adminInfo, dispatch, deleteStore = false) => {
//   if (adminInfo && adminInfo.role === CEO_ROLE) {
//     StoreServices.getAllStores()
//       .then((res) => {
//         if (Array.isArray(res) && res.length > 0) {
//           if (deleteStore) {
//             Cookies.set(COOKIE_KEYS.STORE_INFO, res[0]._id);
//             Cookies.set(COOKIE_KEYS.SELECTED_STORE_IDX, 0);
//             dispatch({ type: SET_STORE_INDEX, payload: 0 });
//           }
//           dispatch({ type: GET_STORE_INFOS, payload: res });
//           console.log("GetStore Sucess =>", res);
//         } else {
//           if (deleteStore) {
//             Cookies.set(COOKIE_KEYS.STORE_INFO, "");
//             Cookies.set(COOKIE_KEYS.SELECTED_STORE_IDX, -1);
//             dispatch({ type: SET_STORE_INDEX, payload: 0 });
//           }
//           dispatch({ type: GET_STORE_INFOS, payload: "" });
//           console.error("GetStore Error @ Empty Store");
//         }
//       })
//       .catch((err) => {
//         console.error("GetStore Error =>", err);
//       });
//   } else {
//     StoreServices.getStoresWithCmsId({ id: adminInfo.cmsId })
//       .then((res) => {
//         if (Array.isArray(res) && res.length > 0) {
//           if (deleteStore) {
//             Cookies.set(COOKIE_KEYS.STORE_INFO, res[0]._id);
//             Cookies.set(COOKIE_KEYS.SELECTED_STORE_IDX, 0);
//             dispatch({ type: SET_STORE_INDEX, payload: 0 });
//           }
//           dispatch({ type: GET_STORE_INFOS, payload: res });
//         } else {
//           if (deleteStore) {
//             Cookies.set(COOKIE_KEYS.STORE_INFO, "");
//             Cookies.set(COOKIE_KEYS.SELECTED_STORE_IDX, -1);
//             dispatch({ type: SET_STORE_INDEX, payload: 0 });
//           }
//           dispatch({ type: GET_STORE_INFOS, payload: "" });
//           console.error("GetStore Error @ Empty Store");
//           notifyError(
//             "There is no registered store. Please contact CMS staff."
//           );
//         }
//       })
//       .catch((err) => {
//         console.error("GetStore Error =>", err);
//       });
//   }
// };

// export const getExportProductData = (products) => {
//   if (products && products.length > 0) {
//     let res = [];
//     products.map((product) => {
//       let _pro = { ...product };
//       _pro.image = JSON.stringify(product.image);
//       _pro.title = JSON.stringify(product.title);
//       _pro.variation = JSON.stringify(product.variation);
//       _pro.description = JSON.stringify(product.description);
//       // _pro.tag = JSON.stringify(product.tag);
//       _pro.addOns = JSON.stringify(product.addOns);
//       _pro.slug = JSON.stringify(product.slug);

//       _pro.new = Number(product.new);
//       _pro.flashSale = Number(product.flashSale);

//       delete _pro._id;
//       delete _pro.storeId;
//       delete _pro.createdAt;
//       delete _pro.updatedAt;
//       delete _pro.__v;

//       res.push(_pro);
//       return product;
//     });
//     return res;
//   }
//   return [];
// };

// export const getImportProductData = (data) => {
//   let _pro = { ...data };
//   _pro.image = JSON.parse(data.image);
//   _pro.title = JSON.parse(data.title);
//   _pro.variation = JSON.parse(data.variation);
//   _pro.description = JSON.parse(data.description);
//   // _pro.tag = JSON.parse(data.tag);
//   _pro.addOns = JSON.parse(data.addOns);
//   _pro.slug = JSON.parse(data.slug);

//   _pro.new = Boolean(data.new);
//   _pro.flashSale = Boolean(data.flashSale);

//   _pro.originalPrice = Number(data.originalPrice);
//   _pro.price = Number(data.price);
//   _pro.quantity = Number(data.quantity);
//   _pro.rating = Number(data.rating);
//   _pro.saleCount = Number(data.saleCount);

//   _pro.storeId = getStoreId();

//   delete _pro._id;

//   return _pro;
// };

export const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      "PNG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64",
      300,
      300
    );
  });

export const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const checkImageSize = (file) => {
  console.log("File Width =>", file.width);
  if (file.width != UPLOAD_IMG_HEIGHT || file.height != UPLOAD_IMG_HEIGHT) {
    return {
      code: "Image size is not correct",
      message: `Image size must be ${UPLOAD_IMG_HEIGHT} x ${UPLOAD_IMG_HEIGHT}`,
    };
  }
  return null;
};

// export const isValidObject = (object) => {
//   if (!object) {
//     return false;
//   }
//   let keys = Object.keys(object);
//   let valid = false;
//   keys.map((key) => {
//     let val = object[key];
//     if (!_.isEmpty(val)) {
//       valid = true;
//     }
//     return key;
//   });

//   return valid;
// };
