import Cookies from "js-cookie";
import React, { createContext, useReducer } from "react";
import {
  USER_LOGIN,
  USER_LOGOUT,
} from "../actions/actions";
import { COOKIE_KEYS } from "../const";

export const AdminContext = createContext();

const initialState = {
  adminInfo: Cookies.get(COOKIE_KEYS.ADMIN_INFO)
    ? JSON.parse(Cookies.get(COOKIE_KEYS.ADMIN_INFO))
    : null,
};

function reducer(state, action) {
  switch (action.type) {
    case USER_LOGIN:
      return { ...state, adminInfo: action.payload };

    case USER_LOGOUT:
      return {
        ...state,
        adminInfo: null,
      };
    default:
      return state;
  }
}

export const AdminProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };
  return (
    <AdminContext.Provider value={value}>{children}</AdminContext.Provider>
  );
};
