import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { AdminProvider } from "./context/AdminContext";
import ThemeSuspense from "./components/theme/ThemeSuspense";
import { Windmill } from "@windmill/react-ui";
import myTheme from "./assets/theme/myTheme";
import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AdminProvider>
    <Suspense fallback={<ThemeSuspense />}>
      <Windmill usePreferences theme={myTheme}>
        <App />
      </Windmill>
    </Suspense>
  </AdminProvider>,
  document.getElementById("root")
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();
